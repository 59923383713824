import { Box, Container, Input, TableBody, TableCell, TableHead, Button, TextField } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function SettingNotify() {
    const [profile, setProfile] = useState(null);
    const [content, setContent] = useState("");
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`https://server.reallovevn.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res?.data?.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                window.location.href = "/login";
            });
        axios
            .get(`https://server.reallovevn.com/auth/getnotify`, {})
            .then((res) => {
                setContent(res?.data?.data?.content);
            })
            .catch(() => {
                setContent("");
            });
    }, []);
    const handleChangeContent = (value) => {
        setContent(value);
    };
    const handleSubmit = () => {
        console.log(profile);
        if (profile?.permission?.edit != 1) {
            return swal("Bạn không có quyền", "", "warning");
        }
        const formData = {
            content: JSON.stringify(content)
        };
        axios
            .post(`https://server.reallovevn.com/auth/updatenotify`, formData)
            .then((res) => {
                swal("Cập nhật thành công!", "", "success");
            })
            .catch((res) => swal("Cập nhật thất bại", "", "error"));
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 8
                        }}>
                        <Container maxWidth={false}>
                            <div style={{ fontSize: "25px", fontWeight: 700 }}>Cài đặt trang thông báo</div>
                            <ReactQuill value={content} onChange={handleChangeContent} />
                            <br />
                            <Button onClick={handleSubmit}>Lưu</Button>
                        </Container>
                    </Box>
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default SettingNotify;
