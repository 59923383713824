import { Link, useNavigate } from "react-router-dom";
import "./bet.css";
import "./profile.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Footer from "../../components/Footer/Footer";
function Trend() {
    const [bet, setBet] = useState(null);
    const [start, setStart] = useState(false);
    const date = new Date();
    const navigate = useNavigate();
    const [state, setState] = useState(null);
    const [total1, setTotal1] = useState(null);
    const [total3, setTotal3] = useState(null);
    const [total5, setTotal5] = useState(null);
    const [isShow, setShow] = useState(false);
    const {
        watch,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [profile, setProfile] = useState(null);
    useEffect(() => {
        if (start === false) {
            axios
                .get(`https://server.reallovevn.com/auth/getUser`, {})
                .then((res) => {
                    setProfile(res.data.data);
                })
                .catch(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
            axios
                .get(`https://server.reallovevn.com/bet/getallbet`, {})
                .then((res) => {
                    setTotal3(res.data.data);
                })
                .catch(() => setTotal3(null));
            axios
                .get(`https://server.reallovevn.com/bet1/getallbet`, {})
                .then((res) => {
                    setTotal1(res.data.data);
                })
                .catch(() => setTotal1(null));
            axios
                .get(`https://server.reallovevn.com/bet5/getallbet`, {})
                .then((res) => {
                    setTotal5(res.data.data);
                })
                .catch(() => setTotal5(null));
        }
    }, [start]);

    const [activeTab, setActiveTab] = useState("tab2");

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    return (
        <>
            <div className="app1">
                <div className="header_profile">
                    <div className="info_profile">
                        <div></div>
                        <div className="avatar_profile">
                            <div className="name_profile"></div>

                            <div
                                style={{
                                    marginLeft: "20px",
                                    fontSize: "1.2rem",
                                    fontWeight: 600,
                                    color: "white"
                                }}>
                                Xu hướng
                            </div>
                        </div>
                        <Link to="/mine" className="icon_setting_header">
                            &times;
                        </Link>
                    </div>
                </div>
                {/*<div className="info_profile">
                    <div className="cycle_bet">
                        <span className="info_bet">Số đơn đã chốt</span>
                    </div>
				</div>
                <nav data-v-260e3e17="" data-v-0f9d40fc="" className="tab-box lottery-game-tab" data-v-435dc89a="" style={{ boxShadow: "none", marginTop: "10px" }}>
                    <div data-v-260e3e17="" className={activeTab === "tab1" ? "tab-item tab-item-active" : "tab-item"} onClick={() => handleTabClick("tab1")}>
                        PHÒNG 1
                    </div>
                    <div data-v-260e3e17="" className={activeTab === "tab2" ? "tab-item tab-item-active" : "tab-item"} onClick={() => handleTabClick("tab2")}>
                        PHÒNG 3
                    </div>
                    <div data-v-260e3e17="" className={activeTab === "tab3" ? "tab-item tab-item-active" : "tab-item"} onClick={() => handleTabClick("tab3")}>
                        PHÒNG 5
                    </div>
                </nav>*/}
                <div className="record_bet">
                    <div className="border_wallet"></div>
                    <div style={{ padding: "0 0 90px" }} className="wrap_history">
                        <div style={{ padding: "10px" }}>
                            <div style={{ background: "#fa546f" }} className="type_item3 title-trend">
                                <div className="trend__result-item">Mã đơn</div>
                                <div style={{ width: "75%" }} className="trend__result-item2">
                                    Kết quả
                                </div>
                            </div>
                            {activeTab === "tab1" && (
                                <>
                                    {total1 != null ? (
                                        <>
                                            {total1.map((item) => (
                                                <>
                                                    <div className="type_item3">
                                                        <div className="trend__result-item">{item.id_bet}</div>
                                                        <div style={{ width: "75%" }} className="trend__result-item2">
                                                            <span>
                                                                <b>{item.result.split(" ")[0]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[1]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[2]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[3]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[4]}</b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    ) : null}
                                </>
                            )}
                            {activeTab === "tab2" && (
                                <>
                                    {total3 != null ? (
                                        <>
                                            {total3.map((item) => (
                                                <>
                                                    <div className="type_item3">
                                                        <div className="trend__result-item">{item.id_bet}</div>
                                                        <div style={{ width: "75%" }} className="trend__result-item2">
                                                            <span>
                                                                <b>{item.result.split(" ")[0]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[1]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[2]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[3]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[4]}</b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    ) : null}
                                </>
                            )}
                            {activeTab === "tab3" && (
                                <>
                                    {total5 != null ? (
                                        <>
                                            {total5.map((item) => (
                                                <>
                                                    <div className="type_item3">
                                                        <div className="trend__result-item">{item.id_bet}</div>
                                                        <div style={{ width: "75%" }} className="trend__result-item2">
                                                            <span>
                                                                <b>{item.result.split(" ")[0]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[1]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[2]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[3]}</b>
                                                            </span>
                                                            <span>
                                                                <b>{item.result.split(" ")[4]}</b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer profile={profile} />
        </>
    );
}
export default Trend;
